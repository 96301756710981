import { inject, Injectable } from '@angular/core';

import { catchError, EMPTY, filter, first, map, mergeMap, Observable, switchMap, tap, throwError, withLatestFrom } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PAYSLIPS_DATA_ADAPTER } from './payslips-data-adapter.token';
import { payslipsDataAdapterActions } from './actions/payslips-data-adapter.actions';
import { payslipsFeatureActions } from './actions/payslips-feature.actions';
import { selectPayslipsListParams } from './payslips.selectors';
import { FileService } from '@ciphr/shared/file-service';
import { authContextFeatureActions } from '@ciphr/core/auth-context/state';
import { Router } from '@angular/router';
import { SnackBarService } from '@ciphr/ui';
import { ErrorDetail, PayslipHttpErrorResponse } from '@ciphr/domains/payslips/models';
import { Dialog } from '@angular/cdk/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { DocumentDownloadConfirmationDrawerComponent } from '@ciphr/shared/download-confirmation-drawer';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';

@Injectable()
export class PayslipsEffects {
  private actions$ = inject(Actions);
  private dataAdapter = inject(PAYSLIPS_DATA_ADAPTER);

  private store = inject(Store);

  private fileService = inject(FileService);
  private translationService = inject(TranslocoService);
  private snackbarService = inject(SnackBarService);
  private breakpointObserver = inject(BreakpointObserver);

  private router = inject(Router);
  private overlay = inject(Overlay);
  private dialog = inject(Dialog);

  loadCurrentPayslip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payslipsFeatureActions.loadCurrentPayslip),
      mergeMap(() =>
        this.dataAdapter.fetchCurrentPayslip().pipe(
          map((payslip) => payslipsDataAdapterActions.currentPayslipLoadedSuccessfully({ payslip })),
          catchError((httpResponseError) => {
            this.store.dispatch(payslipsDataAdapterActions.currentPayslipLoadedFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );

  loadPayslip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payslipsFeatureActions.loadPayslip),
      switchMap(({ id }) =>
        this.dataAdapter.fetchPayslip(id).pipe(
          map((payslip) => payslipsDataAdapterActions.payslipLoadedSuccessfully({ payslip })),
          catchError((httpResponseError) => {
            this.store.dispatch(payslipsDataAdapterActions.payslipLoadedFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );

  loadPayslipsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payslipsFeatureActions.loadPayslipsList),
      mergeMap(() => this.store.select(selectPayslipsListParams).pipe(first())),
      switchMap((params) =>
        this.dataAdapter.fetchPayslipsList(params).pipe(
          map((payslipsList) => payslipsDataAdapterActions.payslipsListLoadedSuccessfully({ payslipsList })),
          catchError((httpErrorResponse: PayslipHttpErrorResponse) => {
            this.store.dispatch(payslipsDataAdapterActions.payslipsListLoadedFailed({ error: httpErrorResponse.error }));
            return httpErrorResponse.error ? EMPTY : throwError(() => httpErrorResponse);
          }),
        ),
      ),
    ),
  );

  downloadPayslip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payslipsFeatureActions.downloadPayslip),
      mergeMap(({ id }) =>
        this.dataAdapter.downloadPayslip(id).pipe(
          tap(({ headers, body }) => this.fileService.downloadFileFromResponseBlob(headers, body)),
          map(() => payslipsDataAdapterActions.payslipDownloadedSuccessfully()),
          catchError((httpResponseError) => {
            this.store.dispatch(payslipsDataAdapterActions.payslipDownloadFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );

  downloadAllPayslip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payslipsFeatureActions.downloadAllPayslips),
      withLatestFrom(this.store.select(selectPayslipsListParams).pipe(map((params) => params.dateRange))),
      mergeMap(([, dateRange]) =>
        this.dataAdapter.downloadPayslips(dateRange).pipe(
          tap(({ headers, body }) => this.fileService.downloadFileFromResponseBlob(headers, body)),
          map(() => payslipsDataAdapterActions.payslipsDownloadSuccessfully()),
          catchError((httpResponseError) => {
            this.store.dispatch(payslipsDataAdapterActions.payslipsDownloadFailed());
            return throwError(() => httpResponseError);
          }),
        ),
      ),
    ),
  );

  resetPayslipsListPaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payslipsFeatureActions.changePayslipsListDateRange),
      map(() => payslipsFeatureActions.resetPayslipsListPaging()),
    ),
  );

  triggerPayslipsListLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        payslipsFeatureActions.changePayslipsListDateRange,
        payslipsFeatureActions.changePayslipsListPage,
        payslipsFeatureActions.sortPayslipsList,
      ),
      map(() => payslipsFeatureActions.loadPayslipsList()),
    ),
  );

  triggerPayslipsAfterCompanyContextChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authContextFeatureActions.selectActiveContext),
      filter(() => this.router.url.startsWith('/payslips')),
      tap(() => this.store.dispatch(payslipsFeatureActions.clearCurrentPayslip())),
      tap(() => this.store.dispatch(payslipsFeatureActions.resetPayslipsListPaging())),
      map(() => payslipsFeatureActions.loadCurrentPayslip()),
    ),
  );

  payslipsLoadingFailed = createEffect(
    () =>
      this.actions$.pipe(
        ofType(payslipsDataAdapterActions.payslipsListLoadedFailed),
        tap(({ error }) => {
          if (error?.details) {
            const message = error.details.length ? error.details.map((err: ErrorDetail) => err.message) : [error.message];
            this.snackbarService.open(message[0], 'error');
          }
        }),
      ),
    { dispatch: false },
  );

  openDownloadConfirmationDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payslipsFeatureActions.openDownloadPayslipsConfirmationDialog),
      mergeMap(({ id }) =>
        this.openDocumentDownloadConfirmationDialog().pipe(
          map(() => (id ? payslipsFeatureActions.downloadPayslip({ id }) : payslipsFeatureActions.downloadAllPayslips())),
        ),
      ),
    ),
  );

  private openDocumentDownloadConfirmationDialog(): Observable<boolean> {
    const title = this.translationService.translate('COMMON.DOCUMENTS.MODALS.DOWNLOAD_CONFIRMATION.TITLE');

    return this.dialog
      .open<boolean>(DocumentDownloadConfirmationDrawerComponent, {
        ariaLabel: title,
        positionStrategy: this.breakpointObserver.isMatched(Breakpoints.WebLandscape)
          ? this.overlay.position().global().centerHorizontally().centerVertically()
          : this.overlay.position().global().bottom().centerHorizontally(),
        data: { title },
      })
      .closed.pipe(filter(Boolean));
  }
}
